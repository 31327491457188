<template>
  <header class='header'>
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
          <div class="log"><img src="../../assets/images/logo.png"/></div>
          <div class="title">
            <div class="t1">网页钢琴</div>
            <div class="t2">author:两行</div>
          </div>
        </RouterLink>
      </h1>
      <ul class="navs">
        <li v-for="nav,i in navs" :key="i">
          <RouterLink :to="nav.link">
            <p-button :active="nav.link==curPath">
              <i v-if="nav.icon" :class="'iconfont '+nav.icon" >&nbsp;</i>
              {{nav.name}}
            </p-button>
          </RouterLink>
        </li>
      </ul>

      <div class="user">
        <RouterLink v-if="user" to="/personal">
          <img :src="user.image.url"/>
          <div>{{user.nickname}}</div>
        </RouterLink>
        <RouterLink v-else to="/login">
          <img src="https://7072-prod-8g535hsw8e587afc-1307606712.tcb.qcloud.la/piano/userImage/user.webp"/>
          <div>未登录</div>
        </RouterLink>
      </div>
      <!-- <div class="search">
        <i class="iconfont icon-search"></i>
        <input type="text" placeholder="搜一搜">
      </div>
      <div class="cart">
        <a class="curr" href="#">
          <i class="iconfont icon-cart"></i><em>2</em>
        </a>
      </div> -->
    </div>
  </header>
</template>

<script>
// import {getLoginUser} from '../../api/user'
// import {queryCollectByUserId} from '../../api/collect'
import {useRoute} from 'vue-router';
import {ref, watch} from 'vue'
//import {useStore} from 'vuex'
export default {
  name: 'Header',
  props:{
    navs:{
      type:Array,
      default:()=>[]
    }
  },
  setup(){
    const route=useRoute();
    const curPath=ref('');
    //curPath.value=route.path;
    watch(()=>route.path,(newVal)=>{
      curPath.value=newVal;
    },{immediate:true})
    //console.log("path",curPath.value)

    //const store=useStore();
    let user=ref(null);
    user.value=JSON.parse(localStorage.getItem("user"));
    // getLoginUser().then((res)=>{
    //   //store.commit('user/setLoginUser',res.data);
    //   queryCollectByUserId(res.data.id).then((resp)=>{
    //     store.commit('collect/setCollects',resp.data);
    //   })
    //   //user.value=res.data;
    // })

    // watch(()=>{return store.state.user.loginUser},(newVal)=>{
    //   user.value=newVal;
    // },{immediate:true})
    return {curPath,user}
  }
}
</script>

<style scoped lang='less'>
.header {
  background: #fff;
  height: 110px;
  .container {
    display: flex;
    //align-items: center;
    .user{
      //border: solid;
      height: 50px;
      width: 150px;
      margin-top: 30px;
      img{
        height: 50px;
        width: 50px;
        border-radius: 25px;
        float: left;
      }
      div{
        float: left;
        margin-left: 20px;
        font-size: 15px;
        font-weight: 800;
        line-height: 50px;
      }
    }
  }
  .logo {
    width: 200px;
    line-height: 100px;
   
    //border: solid;
    img {
      display:block;
      //border: solid;
      height: 70px;
      float: left;
      margin-top: 20px;
      margin-left: 10px;
      -webkit-user-drag: none;
      //width: 100%;
      // text-indent: -9999px;
    }
   
    
    .title{
      text-align: center;
      margin-left: 20px;
      margin-top:20px;
      float: left;
      .t1{
        line-height: 50px;
        font-size: 25px;
      }
      .t2{
        line-height: 10px;
        font-size: 10px;
      }
    }
  }
  .navs {
    margin-top:30px;
    width: 800px;
    //border: solid;
    display: flex;
    margin-left: 20px;
    //justify-content: space-around;
    //padding-left: 20px;
    li{
      margin-right: 10px;
    }
  }
}
</style>