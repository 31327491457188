<template>
<div>
  <HeaderSticky/>
  <Header :navs="nav"/>
  <main>
    <router-view></router-view>
  </main>
  <Footer/>
</div>
</template>

<script>
import HeaderSticky from './components/headerSticky.vue'
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import {charUpEffect} from '../hooks/index'
import {useStore} from 'vuex';
import {ref} from 'vue'
export default {
  name: 'index',
  components:{
    Header,
    Footer,
    HeaderSticky
  },

  setup () {
    const store=useStore();
    const nav=ref([]);
    nav.value=store.state.navs.nav;
    // nav.value={
    //         values:[{name:'首页',link:'/'},
    //                 {name:'发现乐谱',link:'/scoreFound'},
    //                 {name:'开始弹奏',link:'/piano/-1'}],
    //         active:0
    //     }
    // store.commit('navs/setNav',nav.value);

    //watch(()=>{return document.body})
    //鼠标点击效果
    window.onclick=function(event){
      // console.log(event);
      // charUpEffect({},{x:event.clientX,y:event.clientY},document.body)
      charUpEffect({},{x:event.pageX,y:event.pageY},document.body)
    }
    return {nav};
  }
}
</script>

<style scoped lang='less'>
  
</style>