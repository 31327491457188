// 随机颜色
function randomColor() {
    return "rgb(" + (~~(Math.random() * 255)) + "," + (~~(Math.random() * 255)) + "," + (~~(Math.random() * 255)) + ")";
}

export const charUpEffect = (props,position,parent) => {   
    if(props.chars==undefined){
        props.chars=["♫","♩","♪","♬","♫ ♩","♪ ♬","♩ ♪"];
    }     
    if(props.fontSize==undefined){
        props.fontSize=20;
    }
    if(props.showTime==undefined){
        props.showTime=2000;
    }
    if(props.moveSpeed==undefined){
        props.moveSpeed=2;
    }
    if(props.color==undefined){
        props.color="random";
    }

    var index=0;
    index=Math.floor(props.chars.length*Math.random());
    var char=document.createElement("b");
    char.onselectstart=new Function('event.returnValue=false');//防止拖动

    parent.appendChild(char).innerHTML = props.chars[index]; //将b元素添加到页面上
    char.style.cssText = "position: absolute;left:-100%"; //给char元素设置样式

    var x = position.x - props.fontSize/ 2;// 横坐标
    var y = position.y - props.fontSize; // 纵坐标

    var c=null;
    if(props.color=="random"){
        c = randomColor(); // 随机颜色
    }else{
        c=props.color;
    }
    var a = 1; // 透明度
    var s = 1.2; // 放大缩小

    let timer = setInterval(function () { //添加定时器
        if (a <= 0) {
            parent.removeChild(char);
            clearInterval(timer);
        } else {
            char.style.cssText = "font-size:"+props.fontSize+"px;cursor: default;position:absolute;z-index: 99999;color:" +
                c + ";left:" + x + "px;top:" + y + "px;opacity:" + a + ";transform:scale(" +
                s + ");";
            y=y-props.moveSpeed;
            a -= 1/(props.showTime/10);
            s += 0.002;
        }
    }, 10)
}

// 获取元素的绝对位置坐标（相对于组件最外层）
export const getElementPagePosition=(obj)=>{
    var  pos  =  { "top" : 0 ,"left" : 0 };
    if  (obj.offsetParent){
        while  (obj.offsetParent){
            pos.top += obj.offsetTop;
            pos.left += obj.offsetLeft;
            obj = obj.offsetParent;
        }
    }
    return  {x:pos.left, y:pos.top};
}
  