<template>
  <div class="header-sticky" :class="{show:y>=80}">
    <div class="container" v-show="y>=80">
      <Header :navs="nav"/>
    </div>
  </div>
</template>

<script>
import Header from './header.vue';
import { onMounted,ref} from 'vue';
import {useStore} from 'vuex'
//import { useWindowScroll } from '@vueuse/core'
export default {
  name: 'HeaderSticky',
  components: { Header },
  //非组合api实现
  setup () {
    const store=useStore();
    const nav=ref([]);
    nav.value=store.state.navs.nav;

    const y=ref(0);
    console.log(y)
    onMounted(()=>{
      window.onscroll = () => {
        const scrollTop = document.documentElement.scrollTop
        y.value = scrollTop
      }
    })
    return  {y,nav} 
  }

  //使用组合api实现
  //安装：@vueuse/core 包，它封装了常见的一些交互逻辑。
  //npm i @vueuse/core@4.9.0
  //文档查看https://vueuse.org/
//   setup () {
//     const { y } = useWindowScroll()
//     return { y }
//   }
}
</script>

<style scoped lang='less'>
.header-sticky {
  z-index: 9999;
  width: 100%;
  //height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #e4e4e4;

  transform: translateY(-100%);
  opacity: 0;
  &.show {
    transition: all 0.3s linear;
    transform: none;
    opacity: 1;
  }

  .container {
    display: flex;
    align-items: center;
  }
}
</style>
